import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ProgramCategoryContainer = styled.article`
  padding: 96px 0 24px;

  .imageContainer {
    width: 46%;
    min-width: 340px;
    position: relative;

    > .bgColor {
      opacity: 0;
      background-color: ${rspTheme.palette.primary.background};
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: 0.4s ease-in-out;
      transition-delay: 0.5s;

      &.hideBackground {
        opacity: 1;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .imageRight {
    flex-direction: row;
    align-items: center;

    .imageContainer > .gatsby-image-wrapper,
    .imageContainer > .bgColor {
      margin-right: -12%;
      @media (max-width: 810px) {
        margin-right: -8%;
      }
      @media (max-width: 768px) {
        margin-right: 0;
        margin-left: auto;
        right: -4%;
      }
    }
  }

  .imageLeft {
    flex-direction: row-reverse;
    align-items: center;

    .imageContainer > .gatsby-image-wrapper,
    .imageContainer > .bgColor {
      margin-left: -12%;
      @media (max-width: 810px) {
        margin-left: -8%;
      }
      @media (max-width: 768px) {
        margin-left: 0;
        margin-right: auto;
        left: -4%;
      }
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .imageRight,
    .imageLeft {
      flex-direction: column-reverse;
    }
  }
`

export const ContentContainer = styled.div`
  width: 46%;
  min-width: 340px;

  .categorySubhead {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 24px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    width: 100%;

    .categorySubhead {
      font-size: 2.8rem;
      margin-top: 24px;
    }
  }
`

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transition: 0.4s ease-in-out;
  transition-delay: 0.5s;

  .overlayText {
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.4;
    max-width: 44ch;
    margin: 32px auto;
    color: ${rspTheme.palette.primary.main};
  }

  &.hide {
    opacity: 0;
    transform: scale(1.1);
  }

  &.show {
    opacity: 1;
    transform: scale(1);
  }
`

export const LinkGrid = styled.ul`
  margin: 40px 0 16px;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;

  a {
    color: inherit;
    display: inline-block;
    padding: 4px 24px 4px 8px;
    margin-left: -8px;
    border-radius: 4px;
    text-decoration-color: transparent;
    transition: 0.2s ease-in-out;

    &.highlighted {
      color: #b82537;
    }

    &:hover {
      background-color: rgba(33, 21, 74, 0.1);
    }
    &:focus {
      background-color: rgba(33, 21, 74, 0.1);
      text-decoration-color: inherit;
    }

    span {
      position: relative;
    }

    .arrow {
      position: absolute;
      right: -22px;
      bottom: -2px;
    }
  }
`

export const CategoryIconContainer = styled.div`
  width: 150px;
  height: 150px;
  margin: 16px auto 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  > .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;

    > div {
      max-width: 100%;
      max-height: 150px;
    }

    img {
      max-width: 100%;
      max-height: 150px;
      object-fit: contain;
    }
  }
`
