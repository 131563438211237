import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'

import Link, { generateHeadingId } from '../Link'
import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import {
  CategoryIconContainer,
  ContentContainer,
  ImageOverlay,
  LinkGrid,
  ProgramCategoryContainer,
} from './styles'

interface TrainingProgramCategoryProps {
  category: any
  gridClass?: 'imageLeft' | 'imageRight'
  productLinks?: {
    id: string
    url: string
    program: {
      title: string
      short_description: string
      sales_stage: {
        title: string
      }[]
    }[]
  }[]
  primaryUrl?: string
  secondaryUrl?: string
  primaryCtaText?: string
  secondaryCtaText?: string
}

const TrainingProgramCategory = (props: TrainingProgramCategoryProps) => {
  const {
    category,
    gridClass = 'imageRight',
    productLinks = [],
    primaryCtaText,
    primaryUrl,
    secondaryCtaText,
    secondaryUrl,
  } = props
  const image = category?.feature_image
    ? getImage(category?.feature_image?.imgixImage)
    : getImage(category?.feature_image_card?.imgixImage)
  const categoryIconImage = getImage(category?.icon?.imgixImage)
  const [activeOverlay, setActiveOverlay] = useState('')

  return (
    <ProgramCategoryContainer
      id={category?.title?.toLowerCase().replace(/\s/, '-')}
    >
      <Wrapper>
        <Grid className={gridClass} container justifyContent="space-between">
          <ContentContainer>
            <Typography
              className="categorySubhead"
              component="h3"
              id={generateHeadingId(category.headline || category.title)}
              variant="h2"
            >
              {category.headline || category.title}
            </Typography>
            <Typography component="p" variant="subtitle1">
              {category.short_description}
            </Typography>
            {productLinks.length > 0 && (
              <LinkGrid>
                {productLinks?.map((product: any) => (
                  <li key={product?.id}>
                    <Link
                      directUrl={product?.url}
                      link={{ is_highlighted: product?.program?.[0]?.is_highlighted }}
                      onBlur={() => setActiveOverlay('')}
                      onFocus={() =>
                        setActiveOverlay(
                          product?.program?.[0]?.title || product?.title,
                        )
                      }
                      onMouseLeave={() => setActiveOverlay('')}
                      onMouseOver={() =>
                        setActiveOverlay(
                          product?.program?.[0]?.title ||
                            product?.product?.[0]?.title ||
                            product?.title,
                        )
                      }
                    >
                      <span>
                        {product?.program?.[0]?.title ||
                          product?.product?.[0]?.title ||
                          product?.title}
                        <ArrowRightIcon className="arrow" />
                      </span>
                    </Link>
                  </li>
                ))}
              </LinkGrid>
            )}
            {primaryCtaText ? (
              <div style={{ margin: '24px 0' }}>
                <LinkCTA arrow directUrl={primaryUrl || '/'} variant="primary">
                  {primaryCtaText}
                </LinkCTA>
              </div>
            ) : null}
            {secondaryCtaText ? (
              <LinkCTA
                arrow
                directUrl={secondaryUrl || '/'}
                state={{ filterId: category.id }}
                variant="outline"
              >
                {secondaryCtaText}
              </LinkCTA>
            ) : null}
          </ContentContainer>
          <div className="imageContainer">
            {image ? (
              <GatsbyImage
                alt={category?.feature_image?.description || ''}
                image={image}
              />
            ) : null}
            <div
              className={activeOverlay ? 'bgColor hideBackground' : 'bgColor'}
            />
            {productLinks.map((product: any) => {
              const productImage =
                product?.program?.[0]?.icon || product?.product?.[0]?.icon

              const iconImage = productImage
                ? getImage(productImage.imgixImage)
                : categoryIconImage

              return (
                <ImageOverlay
                  className={
                    activeOverlay === product?.program?.[0]?.title ||
                    activeOverlay === product?.product?.[0]?.title ||
                    activeOverlay === product?.title
                      ? 'show'
                      : 'hide'
                  }
                  key={`overlay${product?.id}`}
                >
                  <CategoryIconContainer>
                    {iconImage ? (
                      <GatsbyImage
                        alt={category?.icon?.description || ''}
                        image={iconImage}
                      />
                    ) : null}
                  </CategoryIconContainer>
                  <Typography
                    className="overlayText"
                    component="p"
                    variant="h4"
                  >
                    {product?.program?.[0]?.short_description ||
                      product?.product?.[0]?.short_description ||
                      product?.product_category?.[0]?.short_description}
                  </Typography>
                </ImageOverlay>
              )
            })}
          </div>
        </Grid>
      </Wrapper>
    </ProgramCategoryContainer>
  )
}

export default TrainingProgramCategory
